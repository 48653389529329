var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-list" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "item",
            staticStyle: { flex: "1" },
            attrs: { placeholder: "Search activities" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "item outcome-filter",
              attrs: {
                multiple: "",
                "collapse-tags": "",
                placeholder: "Select outcomes",
              },
              model: {
                value: _vm.outcome_filter,
                callback: function ($$v) {
                  _vm.outcome_filter = $$v
                },
                expression: "outcome_filter",
              },
            },
            _vm._l(_vm.outcome_options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "item resource-filter",
              attrs: {
                multiple: "",
                "collapse-tags": "",
                placeholder: "Select resources",
              },
              model: {
                value: _vm.resource_filter,
                callback: function ($$v) {
                  _vm.resource_filter = $$v
                },
                expression: "resource_filter",
              },
            },
            _vm._l(_vm.resource_options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm.actionLabel
            ? _c(
                "el-button",
                {
                  staticClass: "activity-add item",
                  attrs: { type: "primary" },
                  on: { click: _vm.action },
                },
                [_vm._v(_vm._s(_vm.actionLabel))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.activities,
            "default-sort": { prop: "title", order: "ascending" },
            fit: "",
          },
          on: {
            "row-click": _vm.selectActivity,
            "sort-change": _vm.sortActivities,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Activity",
              prop: "title",
              "min-width": "100px",
              sortable: "custom",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Frequency",
              prop: "frequency_id",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "initial-cap" }, [
                      _vm._v(_vm._s(_vm.frequencies[scope.row.frequency_id])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Duration", prop: "duration", sortable: "custom" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.duration) +
                        " " +
                        _vm._s(_vm._f("pluralize")("min", scope.row.duration)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Difficulty",
              prop: "difficulty",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("difficultyLabel")(scope.row.difficulty)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Evidence",
              prop: "evidence_quality",
              width: "180",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-rate", {
                      attrs: { disabled: "" },
                      model: {
                        value: scope.row.evidence_quality,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "evidence_quality", $$v)
                        },
                        expression: "scope.row.evidence_quality",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Outcomes", prop: "core_items" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._l(scope.row.core_items, function (outcome) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            key: outcome.id,
                            attrs: { content: outcome.name, placement: "top" },
                          },
                          [
                            _c(
                              "el-tag",
                              {
                                staticClass: "outcome",
                                attrs: {
                                  size: "mini",
                                  "disable-transitions": true,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("abbreviate")(outcome.name))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Resources", prop: "resources" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._l(scope.row.resources, function (resource) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            key: resource.id,
                            attrs: { content: resource.name, placement: "top" },
                          },
                          [
                            _c(
                              "el-tag",
                              {
                                staticClass: "resource",
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  "disable-transitions": true,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("abbreviate")(resource.name))
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.query.pagecount
        ? _c("el-pagination", {
            attrs: {
              layout: "prev, pager, next",
              "current-page": _vm.query.page,
              "page-count": _vm.query.pagecount,
            },
            on: { "current-change": _vm.getActivities },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }