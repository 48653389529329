<template>
    <div class="activity-list">

        <div class="filter-container">
            <el-input style="flex: 1" class="item" :placeholder="'Search activities'" v-model="search"/>

            <el-select class="item outcome-filter"
              v-model="outcome_filter" multiple collapse-tags placeholder="Select outcomes">
              <el-option
                v-for="item in outcome_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>

            <el-select class="item resource-filter"
              v-model="resource_filter" multiple collapse-tags placeholder="Select resources">
              <el-option
                v-for="item in resource_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>

            <el-button v-if="actionLabel" class="activity-add item" type="primary" @click="action">{{actionLabel}}</el-button>
        </div>

        <el-table :data="activities" :default-sort = "{prop: 'title', order: 'ascending'}" fit @row-click="selectActivity" @sort-change="sortActivities" v-loading="loading">

            <el-table-column label="Activity" prop="title" min-width="100px" sortable="custom" />
            <el-table-column label="Frequency" prop="frequency_id" sortable="custom" >
              <template slot-scope="scope">
                <span class="initial-cap">{{ frequencies[scope.row.frequency_id] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Duration" prop="duration" sortable="custom" >
              <template slot-scope="scope">
                {{scope.row.duration}} {{'min' | pluralize(scope.row.duration)}}
              </template>
            </el-table-column>
            <el-table-column label="Difficulty" prop="difficulty" sortable="custom" >
              <template slot-scope="scope">
                {{scope.row.difficulty | difficultyLabel }}
              </template>
            </el-table-column>

            <el-table-column label="Evidence" prop="evidence_quality" width="180" sortable="custom" >
              <template slot-scope="scope">
                <el-rate v-model="scope.row.evidence_quality" disabled />
              </template>
            </el-table-column>

            <el-table-column label="Outcomes" prop="core_items" >
              <template slot-scope="scope">
                <template v-for="outcome in scope.row.core_items" >
                  <el-tooltip :key="outcome.id" :content="outcome.name" placement="top">
                    <el-tag class="outcome" size="mini" :disable-transitions="true">{{outcome.name | abbreviate}}</el-tag>
                  </el-tooltip>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Resources" prop="resources" >
              <template slot-scope="scope">
                <template v-for="resource in scope.row.resources">
                  <el-tooltip :key="resource.id" :content="resource.name" placement="top">
                    <el-tag class="resource" type="danger" size="mini" :disable-transitions="true">{{resource.name | abbreviate}}</el-tag>
                  </el-tooltip>
                </template>
              </template>
            </el-table-column>

        </el-table>

        <el-pagination
          v-if="query.pagecount"
          layout="prev, pager, next"
          :current-page="query.page"
          :page-count="query.pagecount"
          @current-change="getActivities"
          >
        </el-pagination>

    </div>
</template>

<script>
    import _ from 'lodash';
    import axios from 'axios';
    import properties from '@/utils/properties';

    export default {
        name: 'activity-list',
        props: {
          limit: {
            required: false,
            default: 15,
            type: Number,
          },
          actionLabel: {
            required: false,
            type: String,
          },
        },
        data() {
            return {
                activities: [],
                loading: true,
                search: '',
                outcome_filter: [],
                resource_filter: [],
                debouncedSearch: '',
                query: {
                    page: 1,
                    pagecount: null,
                    total: null,
                },
                outcome_options: [],
                resource_options: [],
                frequencies: [],
                sort_column: 'title',
                sort_direction: 'asc',
            };
        },
        async mounted() {
            this.outcome_options = await properties.getOutcomeList(this.$auth);
            this.resource_options = await properties.getResourceList(this.$auth);
            this.frequencies = await properties.getFrequencyList(this.$auth);

            this.getActivities();
        },
        watch: {
          search(value) {
            this.searchActivities(value);
          },
          outcome_filter() {
            this.getActivities(1);
          },
          resource_filter() {
            this.getActivities(1);
          }
        },
        filters: {
          abbreviate: function(value) {
            if(!value) return;
            value = value.toString();
            return value.charAt(0).toUpperCase();
          },
          difficultyLabel: function(value) {
            const diff = _.find(properties.getDifficultyList(), item => {
              return item.id == value;
            });
            if(diff) {
              return diff.label;
            }
            else return value;
          }
        },
        methods: {
            async getActivities(page) {
              if(page) {
                this.query.page = page;
              }

              this.loading = true;

              var query = `${process.env.VUE_APP_BASE_URI}/activities?per_page=${this.limit}&page=${this.query.page}`;

              query += `&sort=${this.sort_column}&dir=${this.sort_direction}`;
              if(this.debouncedSearch) {
                query += `&search=${encodeURIComponent(this.debouncedSearch)}`;
              }
              if(this.outcome_filter.length) {
                this.outcome_filter.forEach(filter => { query += `&core_items[]=${filter}` });
              }
              if(this.resource_filter.length) {
                this.resource_filter.forEach(filter => { query += `&resources[]=${filter}` });
              }

              const token = await this.$auth.getTokenSilently();

              axios.get(query,{
                headers: {
                  //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(response => {
                  const data = response.data.data;
                  const meta = response.data.meta;

                  // update query meta
                  if(meta && meta.pagination) {
                    this.query.total = meta.pagination.total;
                    this.query.pagecount = meta.pagination.total_pages;
                  }

                  var activities = [];
                  if(data && data.activities) {
                    data.activities.forEach(activity => {
                      activities.push({ ...activity });
                    });
                  }
                  this.activities = activities;

                  this.loading = false;

              })
              .catch((error) => {
                  this.activities = [];
                  this.loading = false;
                  console.log("API error",error,error.response);
              });
            },

            searchActivities: _.debounce(function(value) {
              this.debouncedSearch = value;
              this.getActivities(1);
            },500),

            action() {
              this.$emit('action');
            },

            selectActivity(activity) {
              this.$emit('select',activity);
            },

            sortActivities(data) {
              this.sort_direction = (data.order == 'ascending' ? 'asc' : 'desc');
              let col = data.prop;
              this.sort_column = col;
              
              this.getActivities();
            }
        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .pagination-container {
        background-color: white;
        margin: 0;
        padding: 20px;
        border: 1px solid #EBEEF5;
        border-top: 0;
        display: flex;
        justify-content: center;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .new-user {
            float: right;
        }

        .outcome-filter, .resource-filter {
          margin-left: 10px;
        }
    }

    .full-width {
        width: 100%;
    }

    .activity-add {
      margin-left: 10px;
    }

    .outcome, .resource {
      margin-right: 4px;
    }

    /deep/ .el-table .cell {
      word-break: normal;
    }

    /deep/ .el-table__row {
      cursor: pointer;
    }

    /deep/ .el-pagination {
      text-align: center;
      button, li {
        background: transparent;
      }
    }

    .initial-cap {
      text-transform: capitalize;
    }

</style>
